import Button from "@/components/atoms/Button";
import DefaultLayout from "@/components/layouts/DefaultLayout";
import LoginForm from "@/components/organisms/Forms/LoginForm";
import { SEO_DEFAULT } from "@/constants/SeoDefault";
import { useRouter } from "next/router";
import * as Yup from "yup";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Correo electrónico inválido")
    .required("Correo electrónico es requerido"),
  password: Yup.string().required("Contraseña es requerida"),
});

const Login = () => {
  const router = useRouter();

  const onRegister = (e: any) => {
    e.preventDefault();
    if (router.query && router.query["redirect_url"]) {
      router.push(
        `/registrar?redirect_url=${router.query["redirect_url"]}`,
        undefined,
        { shallow: true }
      );
    } else {
      router.push("/registrar", undefined, { shallow: true });
    }
  };

  return (
    <DefaultLayout seo={SEO_DEFAULT}>
      <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <h1 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Ingresa a tu cuenta
            </h1>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
              <LoginForm />
              <div className="mb-6 mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">o</span>
                  </div>
                </div>
              </div>
              <Button
                onClick={onRegister}
                variant={"solid"}
                color={"secondary"}
                size={"lg"}
                type={"button"}
                block={true}
              >
                Registrarme
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Login;
