import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useFormContext } from "react-hook-form";
import Label from "./Label";

export type PasswordFieldProps = {
  name: string;
  id: string;
  label?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ label, name, id, ...rest }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const {
      formState: { errors },
    } = useFormContext();
    return (
      <div>
        {label && <Label id={label}>{label}</Label>}
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            ref={ref}
            className="block w-full rounded-md border-0 py-1.5 pr-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            {...rest}
            name={name}
            type={showPassword ? "text" : "password"}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={`Show ${showPassword ? "hide" : "show"} password`}
            className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400"
          >
            {showPassword ? (
              <EyeSlashIcon className="h-5 w-5" />
            ) : (
              <EyeIcon className="h-5 w-5" />
            )}
          </button>
        </div>
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }: any) => (
            <div className="text-sm text-red-500">{message}</div>
          )}
        />
      </div>
    );
  }
);

PasswordField.displayName = "Text_Field";

export default PasswordField;
