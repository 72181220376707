import { ERROR_RESPONSE } from "@/constants/ErrorResponse";
import { LoginInput } from "@/types/Auth";
import { handleAxiosError } from "@/utils/responseUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import Link from "next/link";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Button from "src/components/atoms/Button";
import PasswordField from "src/components/atoms/Form/PasswordField";
import TextField from "src/components/atoms/Form/TextField";
import { useAuth } from "src/contexts/AuthContext";
import AuthService from "src/services/AuthService";
import * as yup from "yup";

type FormData = {
  email: string;
  password: string;
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Correo electrónico inválido")
    .required("Correo electrónico es requerido"),
  password: yup.string().required("Contraseña es requerida"),
});

interface LoginFormProps {
  onLogin?: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const { login, loginMutation } = useAuth();
  const { isLoading, isError, error } = loginMutation;
  const resolver = yupResolver(schema);
  const methods = useForm<FormData>({
    resolver,
  });

  const { register, handleSubmit, watch } = methods;

  const watchEmail = watch("email");

  const onSubmit = handleSubmit((data: LoginInput) => {
    try {
      login(data);
      if (onLogin) onLogin();
    } catch (err) {
      console.log(err);
    }
  });

  const resendVerificationEmail = async (e: any) => {
    e.preventDefault();
    try {
      await AuthService.resendVerificationEmail(watchEmail);
      toast.success("Correo de verificación enviado");
    } catch (err) {
      console.log(err);
      toast.error("Error al enviar correo de verificación");
    }
  };

  const getError = () => {
    const errorPrettified = handleAxiosError(error);
    switch (errorPrettified?.message) {
      case ERROR_RESPONSE.INVALID_CREDENTIALS.type:
        return ERROR_RESPONSE.INVALID_CREDENTIALS.message;
      case ERROR_RESPONSE.NOT_CONFIRMED.type:
        return (
          <div>
            Tu cuenta no esta verificada, debes verificarla para ingresar.{" "}
            <a
              href="#"
              onClick={resendVerificationEmail}
              className="text-blue-700 underline"
            >
              Enviar correor de verificación.
            </a>
          </div>
        );
      default:
        return ERROR_RESPONSE.DEFAULT_ERROR.message;
    }
  };

  return (
    <div>
      {isError && (
        <div className="mb-2 text-center text-solid-red">{getError()}</div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className="space-y-8">
            <TextField
              {...register("email")}
              name="email"
              id="email"
              label="Email"
              placeholder="codealo@example.com"
            />
            <PasswordField
              {...register("password")}
              name="password"
              id="password"
              label="Contraseña"
              placeholder=""
              type="password"
            />
            <div className="flex items-center justify-between">
              {/* <Checkbox id="remember-me" name="remember-me" label="Recuérdame" /> */}
              <div />
              <div className="text-sm">
                <Link
                  href="/auth/olvido-contrasena"
                  className="font-medium text-solid-blue hover:opacity-75"
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </div>
            </div>
            <Button
              onClick={onSubmit}
              variant={"solid"}
              color={"primary"}
              size={"lg"}
              type={"submit"}
              block={true}
              loading={isLoading}
            >
              Inciar Sesión
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default LoginForm;
