import { Seo } from "@/types/Seo";

export const SEO_DEFAULT: Seo = {
  id: 0,
  title: "Codealo",
  description: "La mejor comunidad de programadores en Latino América",
  robots: "noindex",
  canonicalUrl: "https://codealo.dev",
  image: undefined,
  socials: [],
};
