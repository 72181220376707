import { PropsWithChildren } from "react";

export interface LabelProps {
  id: string;
  required?: boolean;
}

const Label: React.FC<PropsWithChildren<LabelProps>> = ({
  id,
  children,
  required,
}) => {
  return (
    <label
      htmlFor={id}
      className="mb-2 block text-sm font-semibold text-gray-900"
    >
      {children}
      {required && <span className="ml-1 text-solid-red">*</span>}
    </label>
  );
};

export default Label;
