import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";
import Label from "./Label";

export type TextFieldProps = {
  label?: string;
  description?: React.ReactNode | JSX.Element | string;
  name: string;
  id: string;
  initialValue?: string;
  disabled?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number | readonly string[];
  reference?: React.Ref<HTMLInputElement>;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      name,
      description,
      id,
      initialValue,
      disabled,
      handleChange,
      value,
      reference,
      className,
      required,
      ...rest
    },
    ref
  ) => {
    const {
      setValue,
      formState: { errors },
    } = useFormContext();

    React.useEffect(() => {
      setValue(name, initialValue);
    }, [initialValue]);

    React.useEffect(() => {
      value && setValue(name, value);
    }, [value]);

    return (
      <div className="w-full">
        {label && (
          <Label id={name} required={required}>
            {label}
          </Label>
        )}
        <div className="mt-2">
          <input
            id={id}
            className={classNames(
              'sm:leading-6" block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm',
              {
                "cursor-not-allowed bg-gray-100": disabled,
              },
              className
            )}
            name={name}
            disabled={disabled}
            ref={ref}
            onChange={handleChange || undefined}
            value={value}
            autoComplete="off"
            {...rest}
          />
        </div>
        {description && <p className="text-sm text-gray-500">{description}</p>}
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }: any) => (
            <div className="my-2 text-sm text-red-500">{message}</div>
          )}
        />
      </div>
    );
  }
);

TextField.displayName = "TextField";

export default TextField;
